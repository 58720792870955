<template>
    <div class="zem-card-title">
        <slot/>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
.zem-card-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: $color-mine-shaft;
    margin-bottom: 8px;
}
</style>
