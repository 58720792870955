<template>
  <!-- Перевод добавлен -->
  <div class="custom-tree-container">
    <div class="block">
      <zem-input v-model="filterText" :placeholder="$t('Filter')" class="mb-1"></zem-input>
      <el-tree
        ref="tree"
        :data="list"
        :draggable="draggable"
        :empty-text="$t('No categories found')"
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
        :show-checkbox="checkboxes"
        class="mt-2"
        node-key="id"
        @node-drag-end="changeTree"
      >
        <span
          slot-scope="{node, data}"
          class="custom-tree-node"
          @contextmenu.prevent="$refs.menu.open($event, {data: data, node: node})"
          @click.self="handleNodeClick(data, node)"
        >
          <span v-if="!data.edit" @click.self="handleNodeClick(data, node)">{{ data.title }} </span>
          <span v-else class="rename-block" @contextmenu.prevent="$refs.menu.open($event, {data: data, node: node})">
            <ZemInput
              v-if="data.new"
              v-model="data.title"
              :placeholder="$t('Creating a section')"
              class="mt-0"
              type="text"
              @update-press-enter="action(addChild ? 'create_children' : 'create', node, data)"
            />
            <ZemInput
              v-else
              v-model="data.title"
              :placeholder="$t('Editing a section')"
              class="mt-0"
              type="text"
              @update-press-enter="action('save_rename', node, data)"
            />
            <zem-preloader v-if="loading" :color="'dark'" />
            <img
              v-else-if="data.new"
              :src="require('@/assets/icons/check_primary.svg')"
              alt=""
              @click.prevent="action(addChild ? 'create_children' : 'create', node, data)"
            />
            <img
              v-else
              :src="require('@/assets/icons/check_primary.svg')"
              alt=""
              @click.prevent="action('save_rename', node, data)"
            />
            <img
              v-if="!loading"
              :src="require('@/assets/icons/close.svg')"
              alt=""
              @click.prevent="action('cancel', node, data)"
            />
          </span>
        </span>
      </el-tree>

      <preloader-wrapper :show="isLoadingTree" />

      <vue-context :ref="`menu`" class="p-0" close-on-click close-on-scroll>
        <template slot-scope="data">
          <ul>
            <li
              v-for="(option, i) of listDropdown"
              :key="i"
              class="menu__dropdown-item"
              @click="selectedDropdown($event, option.value, data.data)"
            >
              <p>
                {{ option.title }}
              </p>
            </li>
          </ul>
        </template>
      </vue-context>
    </div>

    <ZemModal
      :loading-success="loadingDelete"
      :show="showDeleteModal"
      :title-cancel="$t('No')"
      :title-success="$t('Yes')"
      title="Уверены, что хотите удалить группу?"
      @on-change="showDeleteModal = !showDeleteModal"
      @on-success="deleteElTree"
      @on-cancel="showDeleteModal = false"
    />
  </div>
</template>

<script>
import VueContext from 'vue-context'
import ZemInput from '@/components/ui/ZemInput.vue'
import {errorHandler, getAllIds, getAllIdsTree, logger} from '@/assets/scripts/scripts'
import ProductsService from '@/services/products.service'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import {directive as onClickaway} from 'vue-clickaway'
import ZemLink from '@/components/ui/ZemLink.vue'
import {eventBus} from '@/main'
import ZemModal from '@/components/ui/ZemModal.vue'
import PreloaderWrapper from '@/components/PreloaderWrapper.vue'
import ZemTable from '@/components/ui/ZemTable.vue'

let id = 1000

export default {
  components: {
    ZemTable,
    PreloaderWrapper,
    ZemModal,
    ZemLink,
    ZemPreloader,
    ZemInput,
    VueContext,
  },
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    checkboxes: {
      type: Boolean,
      default: false,
    },
    isLoadingTree: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editSection: '',
      filterText: '',
      addChild: false,
      loading: false,
      showDeleteModal: false,
      loadingDelete: false,
      deleteData: null,
      newElement: '',
    }
  },

  computed: {
    listDropdown() {
      let list = []

      if (this.$can('edit', 'position-group-create')) list = [...list,
        {
          title: this.$t('Add Group'),
          value: 2,
        },
        {
          title: this.$t('Add a subgroup'),
          value: 3,
        }]

      if (this.$can('edit', 'position-group-update')) list = [...list,
        {
          title: this.$t('Rename'),
          value: 1,
        },
        {
          title: this.$t('Change'),
          value: 5,
        }]

      if (this.$can('edit', 'position-group-delete')) list = [...list,
        {
          title: this.$t('Delete'),
          value: 4,
        }]

      return list
    },
  },

  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },

  methods: {
    logger,
    changeTree() {
      ProductsService.rebuildTree({
        tree: getAllIdsTree(this.list),
      })
    },
    action(action, node, data) {
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex(d => d.id === data.id)
      const newChild = {
        label: this.$t('New category'),
        edit: true,
        new: true,
        children: [],
      }

      const clearGroup = (r, text) => {
        children[index].new = false
        children[index].edit = false
        children[index] = {
          ...r.data.data,
          children: r.data.data.children === null ? [] : r.data.data.children,
        }
        node.data = {
          ...r.data.data,
          edit: false,
          new: false,
          children: [],
        }

        this.loading = false
        this.$toast.success(this.$t(text))
      }

      const onGroup = (id, create) => {
        this.loading = true
        if (create) {
          ProductsService.createGroup({
            parent_id: id,
            title: data.title,
          })
            .then(r => {
              clearGroup(r, 'The group has been created')
            })
            .catch(e => {
              errorHandler(e)
              this.loading = false
            })
        } else {
          ProductsService.updateGroup(id, {
            title: data.title,
          })
            .then(r => {
              clearGroup(r, 'The group has been updated')
            })
            .catch(e => {
              errorHandler(e)
              this.loading = false
            })
        }
      }

      switch (action) {
        case 'create':
          if (parent.parent === null) {
            onGroup('NxOpZowo9GmjKqdR', true)
          } else {
            onGroup(parent.data.id, true)
          }
          break
        case 'create_children':
          onGroup(parent.data.id, true)
          this.addChild = false
          break
        case 'rename':
          this.$refs.tree.children.forEach(el => {
            el.edit = false
          })
          this.newElement = data.title
          node.data.edit = true
          break
        case 'change':
          eventBus.$emit('set-category-item', {...data, setItem: true})
          this.$store.commit('sidebars/changeRightSidebar', true)
          break
        case 'save_rename':
          onGroup(node.data.id, false)
          // node.data.edit = false;
          break
        case 'remove':
          this.deleteData = {
            node: node,
            data: data,
          }
          this.showDeleteModal = true
          break
        case 'append':
          if (parent.parent === null) {
            parent.data.push(newChild)
          } else {
            parent.data.children.push(newChild)
          }
          break
        case 'append_child':
          this.addChild = true
          if (data.children === null) data.children = []
          data.children.push(newChild)
          node.expanded = true
          break
        case 'cancel':
          if (data.edit) {
            data.edit = false
            data.title = this.newElement
            this.newElement = ''
            if (data.new) {
              const index = children.findIndex(d => d.id === data.id)
              children.splice(index, 1)
            }
          } else {
            data.edit = false
            this.deleteElTree(node, data)
          }
          break
      }
    },

    deleteElTree() {
      this.loadingDelete = true
      ProductsService.deleteGroups(this.deleteData.data.id)
        .then(() => {
          const parentMain = this.deleteData.node.parent
          const children = parentMain.data.children || parentMain.data
          const index = children.findIndex(d => d.id === this.deleteData.data.id)
          children.splice(index, 1)
          this.loadingDelete = false
          this.showDeleteModal = false
        })
        .catch(e => {
          errorHandler(e)
          this.loadingDelete = false
          this.showDeleteModal = false
        })
    },

    filterNode(value, data) {
      if (!value) return true
      return data.title.indexOf(value) !== -1
    },

    selectedDropdown(e, value, data) {
      if (value === 1) this.action('rename', data.node, data.data)
      if (value === 2) this.action('append', data.node, data.data)
      if (value === 3) this.action('append_child', data.node, data.data)
      if (value === 4) this.action('remove', data.node, data.data)
      if (value === 5) this.action('change', data.node, data.data)
    },

    handleNodeClick(data, node) {
      logger(data.id)
      this.$store.commit('products/changePage', 1)
      this.$store.commit('products/selectedPositionCollection', data.id)
      this.$store.commit('products/itemsForDelete', [])
      if (this.$store.state.other.windowWidth < 1024) {
        this.$store.commit('sidebars/changeLeftSidebar', false)
      }
      this.$store.commit('products/selectedSection', data)

      ProductsService.getDirectoryItems()

      eventBus.$emit('set-category-item', data)

      let arr = []
      const createBreadCrumbs = crumb => {
        arr.push(crumb.data.title)
        if (crumb.parent.level !== 0) {
          createBreadCrumbs(crumb.parent)
        }
      }

      createBreadCrumbs(node)

      this.$store.commit('products/breadCrumbs', arr)
    },
  },
}
</script>

<style lang="scss">
.rename-block {
  display: flex;
  align-items: center;
  //margin: -3px 0 0 -9px;

  .zem-input {
    font-size: 14px;
    margin: 0;
    padding: 3px 8px;
    min-height: 24px;
    color: #606266;
  }

  img {
    width: 16px;
    margin-left: 5px;
    cursor: pointer;
  }
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding-right: 8px;
}
</style>
