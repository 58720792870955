import $api from '@/helpers/config-axios'

import authHeader from './auth-header'
import store from '@/store'
import {eventBus} from '@/main'
import {errorHandler} from '@/assets/scripts/scripts'

class ProductsService {
  _refactorDirectories(arr) {
    const get_els = arr => {
      return arr.map(el => {
        const count = el['children']['data'].length > 0
        return {
          ...el,
          edit: false,
          new: false,
          data: [],
          children: count ? get_els(el['children']['data']) : el['children']['data'],
        }
      })
    }

    return get_els(arr)
  }

  async getDirectory() {
    store.commit('other/onChangeLoadingSidebar', true)
    return await $api
      .get('directory/position-groups-tree?include=children', {
        headers: authHeader(),
      })
      .then(r => {
        store.commit('products/updateDirectoriesTree', this._refactorDirectories(r.data.data[0].children.data))
        eventBus.$emit('update-elements-tree')
        store.commit('other/onChangeLoadingSidebar', false)
      })
      .catch(e => {
        errorHandler(e)
      })
  }

  async getDirectoryItems() {
    store.commit('other/onChangeLoadingItems', true)
    const {orderBy, sortedBy, search, paginationPage, positionCollectionId, isArchive} = store.state.products
    let parameters = {
      page: paginationPage,
      include: 'unit,priceList,positionGroups,alternativeFields,properties,createdBy,updatedBy',
      search: search,
      orderBy: orderBy,
      sortedBy: sortedBy,
      position_groups: 'recursive'
    }

    let searchArr = []

    if (positionCollectionId.length > 0) searchArr.push(`position_groups:${positionCollectionId}`)
    if (search) searchArr.push(`title:${search};sku:${search}`)

    parameters.search = searchArr.join(';')

    if (isArchive) parameters['only-trashed'] = `1`

    return await $api
      .get('directory/items', {
        params: parameters,
        headers: authHeader(),
      })
      .then(r => {
        store.commit('products/updateElements', r.data)
        store.commit('products/updatePagination', r.data)
        eventBus.$emit('update-table-settings', r.data.meta.custom.settings.value.data)
        store.commit('other/onChangeLoadingItems', false)
      })
      .catch(e => {
        errorHandler(e)
      })
  }

  async getDirectorySchema(type = '', position = '', id = null) {
    let newParams = {
      // include: "unit,priceList,positionGroups,alternativeFields,properties",
    }
    if (type) newParams['type'] = type
    if (position !== undefined && position.length > 0) newParams['position_group_id'] = position
    if (id) newParams['id'] = id
    return await $api.get('directory/item/schema', {
      params: newParams,
      headers: authHeader(),
    })
  }

  async createDirectoryItems(data) {
    return await $api.post('directory/items', data, {
      headers: authHeader(),
    })
  }

  async updateDirectoryItems(id, data) {
    return await $api.patch('directory/items/' + id, data, {
      headers: authHeader(),
    })
  }

  async updateMultipleDirectoryItems(data) {
    return await $api.patch('directory/items', {data}, {headers: authHeader()})
  }

  async deleteDirectoryItems(permanent = false) {
    let newParams = {}
    if (permanent) newParams['force-delete'] = 1

    return await $api.delete('directory/items', {
      params: newParams,
      headers: authHeader(),
      data: {
        ids: store.state.products.selectedItemsForDelete,
      },
    })
  }

  async archiveDirectoryItems(name) {
    return await $api.post(
      'directory/restore-items',
      {
        ids: store.state[name].selectedItemsForDelete,
      },
      {
        headers: authHeader(),
      }
    )
  }

  async getGroup(id) {
    let parameters = {
      include: 'properties,properties.property,properties.params.properties_name_mask',
    }

    return await $api.get('directory/position-groups/' + id, {
      params: parameters,
      headers: authHeader(),
    })
  }

  async createGroup(data) {
    return await $api.post('directory/position-groups', data, {
      headers: authHeader(),
    })
  }

  async updateGroup(id, data) {
    return await $api.patch('directory/position-groups/' + id, data, {
      headers: authHeader(),
    })
  }

  async deleteGroups(id) {
    return await $api.delete('directory/position-groups/' + id, {
      headers: authHeader(),
    })
  }

  async rebuildTree(body) {
    return await $api
      .post('directory/rebuild-tree-position-groups', body, {
        headers: authHeader(),
      })
      .catch(e => {
        errorHandler(e)
      })
  }
}

export default new ProductsService()
