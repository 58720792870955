var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.product['fields']),function(field,fieldIdx){return _c('div',{key:'f' + fieldIdx},[_c('div',{staticClass:"zem-collapse-table tw-grid mt-1",class:[
        {prices: !_vm.editProduct && !_vm.newProduct},
        field.data.cells.length === 1
          ? 'tw-grid-cols-[35%_auto_max-content]'
          : field.data.cells.length === 2
          ? 'tw-grid-cols-[35%_auto_auto_max-content]'
          : 'tw-grid-cols-[35%_auto_auto_auto_max-content]',
      ]},[_c('div',{staticClass:"zem-collapse-table__column title"}),_vm._l((field.data.cells),function(cell,cellIdx){return _c('div',{key:'g' + cellIdx,staticClass:"zem-collapse-table__column title tw-text-[11px] tw-text-[#9ba6b2]"},[_vm._v(" "+_vm._s(!_vm.showColumn(cell) ? cell : '')+" ")])}),_c('div',{staticClass:"zem-collapse-table__column title"}),_vm._l((field.data['prices']),function(price,priceIdx){return [_c('div',{key:'h' + priceIdx,staticClass:"zem-collapse-table__column title overflow-unset"},[(_vm.editProduct || _vm.newProduct)?[_c('ZemDropdownList',{staticClass:"mt-0",attrs:{"loading":field.loading,"options":_vm.product['options'],"type":"object"},on:{"open":function($event){return _vm.getOptionsForPrices(field)}},model:{value:(price.data),callback:function ($$v) {_vm.$set(price, "data", $$v)},expression:"price.data"}})]:[_c('span',{staticClass:"text-truncate tw-text-[#9ba6b2] tw-text-xs"},[_vm._v(_vm._s(price.data.title))])]],2),_vm._l((price.fields),function(fieldPrice){return _c('div',{staticClass:"zem-collapse-table__column text tw-flex tw-items-center tw-text-xs"},[(_vm.editProduct || _vm.newProduct)?[(!_vm.accessRole(fieldPrice.title))?_c('zem-input',{staticClass:"mt-0",attrs:{"disabled":_vm.accessRole(fieldPrice.title),"type":"text"},on:{"input":function($event){return _vm.rateRecalculation(fieldPrice, price)}},model:{value:(fieldPrice.value),callback:function ($$v) {_vm.$set(fieldPrice, "value", $$v)},expression:"fieldPrice.value"}}):_vm._e()]:[_vm._v(" "+_vm._s(fieldPrice.value)+" ")]],2)}),_c('div',{staticClass:"zem-collapse-table__column end_delete tw-flex tw-items-center"},[(
              (_vm.editProduct || _vm.newProduct) &&
              priceIdx > 0 &&
              _vm.$can('edit' || null, 'directory-item-delete-region-to-price-list' || null)
            )?_c('div',{staticClass:"zem-collapse-table__row text tw-cursor-pointer",on:{"click":function($event){return _vm.deleteItem(_vm.product, priceIdx)}}},[_c('img',{staticClass:"delete_item tw-size-5",attrs:{"src":require('@/assets/icons/close-r.svg'),"alt":""}})]):_vm._e()])]})],2)])}),((_vm.newProduct || _vm.editProduct) && _vm.$can('edit' || null, 'directory-item-add-region-to-price-list' || null))?_c('div',{staticClass:"align-center colored colored--link mt-1",staticStyle:{"display":"inline-flex"},on:{"click":function($event){return _vm.addItem(_vm.product, _vm.idx)}}},[_c('img',{staticClass:"mr-1",attrs:{"src":require('@/assets/icons/plus_primary.svg'),"alt":""}}),_vm._v(" "+_vm._s(_vm.product['fields'][1].title)+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }