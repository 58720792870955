<template>
  <div>
    <div class="radio-wrapper">
      <!--            <ZemRadioButton-->
      <!--                v-for="(mode, mode_index) in fields[0].options"-->
      <!--                :key="'b' + mode_index"-->
      <!--                :class="{ 'mr-3': mode_index + 1 !== fields[0].options.length }"-->
      <!--                :label="mode.title"-->
      <!--                :value="mode.value"-->
      <!--                disabled-->
      <!--                v-model="product['fields'][0].data"-->
      <!--            />-->
      <ZemRadioButton
        v-for="(mode, mode_index) in fields[0].options"
        :key="'b' + mode_index"
        v-model="selected"
        :class="{'mr-3': mode_index + 1 !== fields[0].options.length}"
        :label="mode.title"
        :value="mode.value"
        disabled
      />
    </div>
    <div class="zem-collapse-table rows">
      <!--            <div class="zem-collapse-table__row"-->
      <!--            :class="{ 'characteristics': fields[0].data === 'mask' }">-->
      <div :class="{characteristics: selected === 'mask'}" class="zem-collapse-table__row">
        <div :class="{'required-field': editProduct || newProduct}" class="zem-collapse-table__column title">
          {{ fields[2].title }}
        </div>
        <div v-if="!editProduct && !newProduct" class="zem-collapse-table__column text">
          <!--                    <template v-if="fields[0].data === 'manual'">-->
          {{ fields[2].data.schema.value }}
          <!--                    </template>-->
          <!--                    <template v-else>-->
          <!--                        {{ fields[1].data.value }}-->
          <!--                    </template>-->
        </div>
        <!--                <zem-input-->
        <!--                    v-else-if="fields[0].data === 'manual'"-->
        <!--                    v-model="product['fields'][2].data.schema.value"-->
        <!--                    class="mt-0"-->
        <!--                    :type="fields[0].type === 'numeric' ? 'number' : 'text'"-->
        <!--                ></zem-input>-->
        <zem-input
          v-else-if="selected === 'manual'"
          :disabled="$can('read', 'directory-item-edit-title')"
          v-model="product['fields'][2].data.schema.value"
          :type="fields[0].type === 'numeric' ? 'number' : 'text'"
          class="mt-0"
        ></zem-input>
        <template v-else-if="selected === 'mask'">
          <zem-input
            v-model="product['fields'][1].data.schema[1].value"
            :disabled="$can('read', 'directory-item-edit-title')"
            :type="fields[0].type === 'numeric' ? 'number' : 'text'"
            class="mt-0"
            placeholder="Префикс"
          ></zem-input>
          <ZemMultiSelect
            :disabled="$can('read', 'directory-item-edit-title')"
            v-model="product['fields'][1].data.schema[2].value"
            :options="fields[1].data.schema[2].options"
            type="object"
          />
        </template>
      </div>
    </div>
    <p class="text-normal text-primary mt-4 mb-2">{{ fields[3].title }}</p>
    <div class="zem-collapse-table rows">
      <div
        v-for="(item, i) in fields[3].data.value"
        :key="'d' + i"
        :class="{alt_names: editProduct || newProduct}"
        class="zem-collapse-table__row"
      >
        <div v-if="!editProduct && !newProduct" class="zem-collapse-table__column title">
          {{ item.brand.title }}
        </div>
        <ZemDropdownList v-else v-model="item.brand" :options="fields[3].data['brands']" type="object" />
        <div v-if="!editProduct && !newProduct" class="zem-collapse-table__column text">
          {{ item.title }}
        </div>
        <zem-input v-else v-model="item.title" class="mt-0"></zem-input>
        <ZemMultiSelect v-model="item.title" :loading="item.loading" :options="item.options" type="object" />
        <div v-if="editProduct || newProduct" class="zem-collapse-table__column end_delete">
          <div class="zem-collapse-table__row text text-h cursor-pointer" @click="deleteItem(product, i, idx)">
            <img :src="require('@/assets/icons/close-r.svg')" alt="" class="delete_item" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="(newProduct || editProduct) && fields[3].data.value.length === 0"
      class="colored colored--link"
      style="display: inline-flex"
      @click="addItem(product, idx)"
    >
      <img :src="require('@/assets/icons/plus_primary.svg')" alt="" />
      {{ fields[4].title }}
    </div>
  </div>
</template>

<script>
import ZemInput from '@/components/ui/ZemInput.vue'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import {options} from '@/mixins/options'
import ZemRadioButton from '@/components/ui/ZemRadioButton.vue'

export default {
  mixins: [options],
  components: {
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
    ZemRadioButton,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    editProduct: {
      type: Boolean,
      default: false,
    },
    newProduct: {
      type: Boolean,
      default: false,
    },
    idx: {
      type: Number,
      default: null,
    },
    prefix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      block: {
        identifier: '',
        inputTypeOptions: [],
      },
      selected: 'manual',
    }
  },
  computed: {
    fields() {
      return this.product.fields
    },
    readProduct() {
      return !this.editProduct && !this.newProduct
    },
    onPrefix: {
      get() {
        return this.prefix
      },
      set(newValue) {
        this.$emit('update-prefix', newValue)
      },
    },
  },
  methods: {
    disabledMode() {
      // if (this.readProduct) {
      //     return true
      // } else {
      //     return this.fields[1].data.schema[2].options.length === 0
      // }
      return this.readProduct
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-dropdown {
  margin-top: 0;
}

.radio-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.colored {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: $color-accent;

  img {
    margin-right: 3px;
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
