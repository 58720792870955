<template>
    <div>
        <div
            v-for="(field, index) in product['fields']"
            :key="'a' + index"
            class="zem-collapse-table__row"
        >
            <template v-if="field.data.length > 0">
                <div
                    v-for="(item, i) in field.data"
                    :key="'d' + i"
                    class="zem2-collapse-table properties-list"
                >
                    <div class="zem2-collapse-table__item title d-flex align-center">
                        {{ item.name }}
                    </div>
                    <template v-if="editProduct || newProduct">
                        <div
                            class="zem2-collapse-table__item"
                        >
                            <ZemDropdownList
                                v-if="item.units.length > 0"
                                v-model="item.unit"
                                type="object"
                                :options="item.units"
                            />
                        </div>
                        <div class="zem2-collapse-table__item">
                            <zem-input
                                v-model="item.value"
                                class="mt-0"
                            ></zem-input>
                        </div>
                    </template>
                    <template v-else>
                        <div class="zem2-collapse-table__item text d-flex align-center">
                            {{ item.unit ? item.unit.title : '' }}
                        </div>
                        <div class="zem2-collapse-table__item text d-flex align-center">
                            {{ item.value }}
                        </div>
                    </template>
                </div>
            </template>
            <template v-else>
                <span class="text-normal text-muted">{{ field.info }}</span>
            </template>

        </div>
    </div>
</template>

<script>
import ZemInput from "@/components/ui/ZemInput.vue";
import ZemDropdownList from "@/components/ui/ZemDropdownList.vue";
import ZemMultiSelect from "@/components/ui/ZemMultiSelect.vue";
import {options} from "@/mixins/options";
import ZemRadioButton from "@/components/ui/ZemRadioButton.vue";
import OtherService from "@/services/other.service";

export default {
    mixins: [options],
    components: {
        ZemInput,
        ZemDropdownList,
        ZemMultiSelect,
        ZemRadioButton,
    },
    props: {
        product: {
            type: Object,
            default: () => {
            }
        },
        editProduct: {
            type: Boolean,
            default: false
        },
        newProduct: {
            type: Boolean,
            default: false
        },
        idx: {
            type: Number,
            default: null
        },
    },
    data() {
        return {}
    },
    methods: {
        getOptionsForPrices(el, price, product_id) {
            OtherService.getOptions(el['api_path'])
                .then(r => {
                    this.product.schema[product_id].options = r.data.data
                    this.loadOptions.push(el['api_path'])
                })
        },
    }
}
</script>

<style scoped lang="scss">
.zem-dropdown {
    margin-top: 0;
}
</style>