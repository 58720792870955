<template>
    <div class="zem-card">
        <slot/>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
.zem-card {
    position: relative;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    padding: 12px 15px;
}
</style>
