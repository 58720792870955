<template>
  <!-- Перевод добавлен -->
  <div class="filter-products">
    <div class="filter-products__label">
      <div class="d-flex" style="flex-direction: column; align-items: flex-start;">
        <span>{{ $t('The product tree') }}</span>
        <zem-link
          class="mt-1 text-normal d-flex justify-content-end"
          style="position: relative; bottom: -2px;"
          @click="handleNodeClickAway"
        >{{ $t('Reset all') }}
        </zem-link>
      </div>
      <div
        v-if="$store.getters['other/mobileContent']"
        @click="() => $store.commit('sidebars/changeLeftSidebar', false)"
      >
        <font-ai class="text-primary" icon="fa-solid fa-xmark"/>
      </div>
    </div>
    <div class="filter-products__bread-crumbs">
      <div>
        <span>{{ breadCrumbsList.slice(0, -1).join(' / ') }}</span>
        <span class="filter-products__bread-crumbs__last">
          {{ breadCrumbsList.length > 1 ? '/' : '' }} {{ breadCrumbsList[breadCrumbsList.length - 1] }}
        </span>
      </div>
    </div>
    <p v-if="showCreateFirstDirectory" class="zem-link" @click="addFirstCategory">
      {{ $t('Create the first category') }}
    </p>
    <template v-if="!showCreateFirstDirectory">
      <!--      <Checkbox-->
      <!--        v-if="$can('edit', 'position-group-read')"-->
      <!--        :value="$store.state.products.activeDragAndDrop"-->
      <!--        class="checkbox-dnd"-->
      <!--        @input="-->
      <!--          () => {-->
      <!--            $store.commit('products/updateDragAndDrop')-->
      <!--          }-->
      <!--        "-->
      <!--      >-->
      <!--        Перетаскивание-->
      <!--      </Checkbox>-->
      <!--            <Checkbox-->
      <!--                :value="$store.state.products.activeMultipleChoice"-->
      <!--                @input="() => {$store.commit('products/updateMultipleChoice')}"-->
      <!--                class="mb-1"-->
      <!--            >Множественный выбор-->
      <!--            </Checkbox>-->
      <TestTree
        v-if="$can('edit', 'position-group-read')"
        ref="treeContainer"
        :checkboxes="$store.state.products.activeMultipleChoice"
        :draggable="$can('edit', 'position-group-drag')"
        :is-loading-tree="$store.state.other.isLoadingSidebar"
        :list="elements"
      />
    </template>
  </div>
</template>

<script>
import Checkbox from '@/components/ui/Checkbox'
import ProductsService from '@/services/products.service'
import {eventBus} from '@/main'
import TestTree from '@/components/ui/TestTree.vue'
import ZemLink from '@/components/ui/ZemLink.vue'

export default {
  components: {
    TestTree,
    Checkbox,
    ZemLink,
  },

  data() {
    return {
      elements: [],
      showCreateFirstDirectory: undefined,
    }
  },

  computed: {
    breadCrumbsList() {
      return this.$store.state.products.breadCrumbs.reverse()
    },
  },

  mounted() {
    this.getDirectory()
    this.elements = this.$store.state.products.directoriesTree
    eventBus.$on('update-elements-tree', () => {
      if (this.$store.state.products.directoriesTree.length === 0) this.showCreateFirstDirectory = true
      this.elements = this.$store.state.products.directoriesTree
    })
  },

  methods: {
    getDirectory() {
      ProductsService.getDirectory()
    },
    addFirstCategory() {
      this.$store.commit('products/updateDirectoriesTree', [
        {
          title: '',
          real_id: 0,
          edit: true,
          new: true,
          children: [],
        },
      ])
      this.elements = this.$store.state.products.directoriesTree
      this.showCreateFirstDirectory = false
    },

    handleNodeClickAway() {
      this.$store.commit('products/getSearch', '')
      this.$store.commit('products/selectedPositionCollection', [])
      this.$store.commit('products/itemsForDelete', [])
      this.$store.commit('sidebars/changeRightSidebar', false)
      this.$store.commit('products/selectedSection', {
        title: '',
        id: '',
      })
      this.$refs.treeContainer.filterText = ''

      ProductsService.getDirectoryItems()

      eventBus.$emit('set-product', null)

      this.$store.commit('products/breadCrumbs', [])

      this.$refs.treeContainer.$refs.tree.setCurrentNode([])
    },
  },
}
</script>

<style lang="scss">
.filter-products {
  display: block;
  padding: 20px 24px;
  min-width: 192px;
  overflow: auto;
  height: 100%;

  .checkbox-dnd {
    margin-bottom: 6px;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: $color-mine-shaft;
  }

  &__bread-crumbs {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: $color-gull-gray;
    height: 14px;

    &__last {
      color: $color-accent;
    }
  }

  > .drag-area {
    margin-left: 0;
    height: auto;
    transition: all 0.4s ease;
    overflow: unset;

    > .item-group {
      display: block;

      .drag-area__name {
        font-size: 14px;
        line-height: 19px;
        min-height: 26px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .filter-products {
    padding: 20px;
    min-width: 152px;
  }
}
</style>
