<template>
  <zem-card class="product-header">
    <div>
      <template v-if="product || creating">
        <zem-card-title>
          {{ creating ? $t('New product') : product.title }}
        </zem-card-title>
        <div class="block-id">
          <span class="block-id__label"></span>
          ID {{ product.number }}
        </div>
      </template>
      <template v-else-if="productData">
        <zem-card-title> {{ $t('Subgroup') }}: {{ productData.title }}</zem-card-title>
        <div v-if="!creating" class="block-id">
          <span class="block-id__label"></span>
          ID #{{ productData.real_id }}
        </div>
      </template>
      <template v-else-if="product">
        <zem-card-title>{{ product.title.replaceAll('-', ' ') }}</zem-card-title>
        <div class="block-id">
          <span class="block-id__label"></span>
          ID {{ product.number }}
        </div>
      </template>
      <div class="block-id" v-if="product">
        <span class="block-id__label second"></span>
        ID {{ product.id }}
      </div>
    </div>
    <div>
      <template v-if="(!productData || creating) && !loading">
        <!--        <template v-if="!creating && $can('edit', 'directory-item-update')">-->
        <!--          <img-->
        <!--            :src="require('@/assets/icons/edit.svg')"-->
        <!--            alt=""-->
        <!--            class="block-id__edit ml-3"-->
        <!--            v-if="$can('edit', 'directory-item-update')"-->
        <!--            @click="-->
        <!--              () => {-->
        <!--                $emit('edit-product')-->
        <!--              }-->
        <!--            "-->
        <!--          />-->
        <!--          <img :src="require('@/assets/icons/close.svg')" alt="" class="block-id__delete" @click="closeRightSidebar" />-->
        <!--        </template>-->
        <template v-if="$can('edit', 'directory-item-update')">
          <zem-link
            class="mr-2 mobile-d-none"
            @click="
              () => {
                $emit('close')
              }
            "
          >
            Закрыть
          </zem-link>
          <zem-button
            class="mobile-d-none"
            @click="
              () => {
                $emit(creating ? 'create' : 'update')
              }
            "
          >
            Сохранить
          </zem-button>
        </template>
      </template>

      <zem-button v-if="loading" disabled>
        <zem-preloader color="light" />
      </zem-button>
      <font-ai
        v-if="productData"
        class="block-id__delete ml-2"
        icon="fa-solid fa-xmark"
        @click="
          () => {
            $emit('close')
          }
        "
      />
    </div>
  </zem-card>
</template>

<script>
import ZemCard from '@/components/ui/ZemCard.vue'
import ZemCardTitle from '@/components/ui/ZemCardTitle.vue'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import {closeAcc} from '@/assets/scripts/scripts'

export default {
  components: {
    ZemButton,
    ZemCard,
    ZemCardTitle,
    ZemPreloader,
    ZemLink,
  },
  props: ['editing', 'creating', 'productData', 'loading', 'product'],
  data() {
    return {}
  },
  computed: {
    typeMode() {
      let index = this.product.schema.findIndex(el => el.identifier === 'title')
      return this.product.schema.length > 0 ? this.product.schema[index]['fields'][0].data.mode : 'manual'
    },
  },
  methods: {
    closeAcc,
    closeRightSidebar() {
      this.$store.commit('sidebars/changeRightSidebar', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  > div:last-child {
    display: flex;

    img:not(:first-child) {
      margin-left: 8px;
    }

    .block-id__delete {
      width: 17px;
      color: $color-accent;
    }
  }
}

.zem-dropdown {
  margin-top: 0;
}

@media screen and (max-width: 1024px) {
  .product-header {
    > div:first-child {
      width: 100%;
    }

    .block-id__edit {
      max-height: 17px;
      min-width: 12px;
    }

    .block-id__delete {
      max-height: 17px;
      min-width: 17px;
    }
  }
}
</style>
