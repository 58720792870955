import OtherService from '@/services/other.service'

export const options = {
  data() {
    return {
      loadOptions: [],
    }
  },
  methods: {
    getOptions(el, idx, index = null, root = false) {
      if (el['input_name'] === 'type' || el['input_name'] === 'dynamic_property_id') {
      } else {
        el.loading = true
        OtherService.getOptions(el['api_path'], root).then(r => {
          el.options = r.data.data
          this.loadOptions.push(el['api_path'])
          el.loading = false
        })
      }
    },
    deleteItem(product, itemIndex) {
      if (product.identifier === 'title') {
        product.fields[3].data.value.splice(itemIndex, 1)
      } else if (product.identifier === 'price-list') {
        product.fields[0].data.prices.splice(itemIndex, 1)
      } else if (product.identifier === 'related-kit-items') {
        product.fields[0].data.splice(itemIndex, 1)
      } else {
        product.data.splice(itemIndex, 1)
      }
    },
    addItem(product) {
      if (product.identifier === 'title') {
        product.fields[3].data.value.push({
          brand: '',
          title: '',
        })
      } else if (product.identifier === 'related-kit-items') {
        product.fields[0].data.push({
          value: {
            unit: {
              data: {
                name: '',
              },
            },
            count: 1,
          },
        })
      } else if (product.identifier === 'price-list') {
        let newPrice = {
          fields: [],
          data: {
            title: '',
            value: '',
          },
          loading: false,
        }
        product.fields[0].data.prices[0].fields.forEach(el => {
          newPrice.fields.push({
            ...el,
            value: 0,
          })
        })
        product.fields[0].data.prices.push(newPrice)
      }
    },
  },
}
