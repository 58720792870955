<!--/**-->
<!--* @usage:-->
<!--*-->
<!--*   <RadioBox label="Foo" value="foo" v-model="MySelectedValue" />-->
<!--*   <RadioBox label="Bar" value="bar" v-model="MySelectedValue" />-->
<!--*   <RadioBox label="Baz" value="baz" v-model="MySelectedValue" />-->
<!--*-->
<!--* data(){-->
<!--*    return {-->
<!--*      MySelectedValue: "",-->
<!--*    }-->
<!--*  }-->
<!--*/-->

<template>
  <label class="wrapper">
    {{ label }}
    <input
      :checked="isChecked"
      :disabled="disabled"
      :value="value"
      class="checkbox"
      type="radio"
      @change="$emit('change', $event.target.value)"
    />
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    label: {type: String, default: '', required: true},
    modelValue: {default: ''},
    value: {type: [String, Boolean], default: undefined},
    disabled: {type: [String, Boolean], default: undefined},
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
/* Customize the label (the wrapper) */
.wrapper {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 18px;
  margin-bottom: 6px;
  cursor: pointer;
  /*font-size: 22px;*/
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  /* identical to box height, or 111% */

  /* text/dark */

  color: #3e3e3e;
}

/* Hide the browser's default radio button */
.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  outline: 1px solid #d9d9d9;
}

/* On mouse-over, add a grey background color */
.wrapper:hover input ~ .checkmark {
  background-color: #efefef;
}

/* When the radio button is checked, add a blue background */
/*.wrapper input:checked ~ .checkmark {*/
/*    background-color: #1CD4A7;*/
/*}*/
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.wrapper input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.wrapper .checkmark:after {
  top: 2px;
  left: 2px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $color-accent;
}
</style>
