<template>
    <div class="zem-collapse-table columns mt-1" style="grid-template-columns: auto;">
        <template v-for="(item, itemIdx) in product['fields'][0].data">
            <div
                class="zem-collapse-table__column title overflow-unset"
                :key="'h' + itemIdx"
            >
                <span class="text-truncate">{{ item.title }}</span>
            </div>
        </template>
    </div>
</template>

<script>
import ZemInput from "@/components/ui/ZemInput.vue";
import ZemDropdownList from "@/components/ui/ZemDropdownList.vue";
import ZemMultiSelect from "@/components/ui/ZemMultiSelect.vue";
import {options} from "@/mixins/options";
import ZemRadioButton from "@/components/ui/ZemRadioButton.vue";
import OtherService from "@/services/other.service";

export default {
    mixins: [options],
    components: {
        ZemInput,
        ZemDropdownList,
        ZemMultiSelect,
        ZemRadioButton,
    },
    props: {
        product: {
            type: Object,
            default: () => {
            }
        },
        editProduct: {
            type: Boolean,
            default: false
        },
        newProduct: {
            type: Boolean,
            default: false
        },
        idx: {
            type: Number,
            default: null
        },
    },
    data() {
        return {}
    },
}
</script>

<style scoped lang="scss">
.zem-dropdown {
    margin-top: 0;
}
</style>