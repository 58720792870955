<template>
  <div>
    <div class="zem-collapse-table columns mt-1" style="grid-template-columns: 50% 20% 24% 2%">
      <template v-for="(item, itemIdx) in product['fields'][0].data">
        <div :key="'h' + itemIdx" class="zem-collapse-table__column title overflow-unset">
          <template v-if="editProduct || newProduct">
            <ZemDropdownList
              v-model="item.value"
              :options="product['options']"
              class="mt-0 bg-white"
              type="object"
              @open="getOptionsForRelatedKit(product['fields'][0], itemIdx, idx)"
            />
          </template>
          <template v-else>
            <span class="text-truncate">{{ item.value.title }}</span>
          </template>
        </div>
        <div class="zem-collapse-table__column text">
          <template v-if="editProduct || newProduct">
            <ZemInput
              v-model="item.value.unit.data.name"
              :options="product['options']"
              class="mt-0"
              disabled
              type="object"
            />
          </template>
          <template v-else>
            <span class="text-truncate">{{ item.value.unit.data.name }}</span>
          </template>
        </div>
        <div class="zem-collapse-table__column text">
          <template v-if="editProduct || newProduct">
            <zem-input v-model="item.value.count" class="mt-0"></zem-input>
          </template>
          <template v-else>
            {{ item.value.count }}
          </template>
        </div>
        <div v-if="editProduct || newProduct" class="zem-collapse-table__column end_delete">
          <div class="zem-collapse-table__row text text-h cursor-pointer" @click="deleteItem(product, idx)">
            <img :src="require('@/assets/icons/close-r.svg')" alt="" class="delete_item" />
          </div>
        </div>
        <div v-else></div>
      </template>
    </div>

    <div
      v-if="newProduct || editProduct"
      class="d-flex align-center colored colored--link mt-2"
      @click="addItem(product, idx)"
    >
      <img :src="require('@/assets/icons/plus_primary.svg')" alt="" class="mr-1" />
      {{ product['fields'][1].title }}
    </div>
  </div>
</template>

<script>
import ZemInput from '@/components/ui/ZemInput.vue'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import {options} from '@/mixins/options'
import ZemRadioButton from '@/components/ui/ZemRadioButton.vue'
import OtherService from '@/services/other.service'

export default {
  mixins: [options],
  components: {
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
    ZemRadioButton,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    editProduct: {
      type: Boolean,
      default: false,
    },
    newProduct: {
      type: Boolean,
      default: false,
    },
    idx: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {}
  },
  methods: {
    getOptionsForRelatedKit(el) {
      el.loading = true
      OtherService.getProducts().then(r => {
        el.loading = false
        this.product.options = r.data.data
        this.loadOptions.push(el['api_path'])
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-dropdown {
  margin-top: 0;
}
</style>
